import { useCallback } from "react";
import {
  GetLessonById,
  GetLessonByIdVariables,
} from "../__generated__/gql-types/GetLessonById";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

const UseGetLessonById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: GetLessonByIdVariables) => {
      const result: ApolloQueryResult<GetLessonById> = await client.query({
        query: GET_LESSON_BY_ID,
        fetchPolicy: "no-cache",
        variables,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonById;
    },
    [client]
  );
};

export default UseGetLessonById;

const GET_LESSON_BY_ID = gql`
  query GetLessonById($lessonId: ID!) {
    getLessonById(lessonId: $lessonId) {
      id
      title
      description
      cover
      week
      sep_book_pages
      sep_name
      featured
      publish
      nem
      order_reading
      drive_link
      result
      lesson_type
      learnings
      finished
      grade {
        id
        name
      }
      subject {
        id
        name
      }
      axes {
        id
        name
      }
      themes {
        id
        name
      }
      tags {
        id
        name
      }
      lesson_content {
        redaction {
          id
          title
          question
          rubrics {
            rubric
            order
          }
        }
        vocabulary {
          id
          isBasics
          cover
          items {
            id
            
            wordId
            vw {
              id
              gifFileId
              spanishWord
              englishWord
              synonyms
              wordType
              clue {
                id
                example
                type
              }
              createdAt
              updatedAt
            }
          }
        }
        listening {
          id
          title
        }
        aiconversations {
          id
          title
        }
        literacy {
          id
          title
        }
        quizzes {
          id
          title
        }
        videos {
          id
          title
        }
        pdf
        slide
        responses_pdf
        id
        lesson_id
      }
    }
  }
`;
