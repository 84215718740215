import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getToolsIA } from "../__generated__/gql-types/getToolsIA";

const UseGetToolsIA = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getToolsIA> = await client.query({
      query: GET_TOOLS_IA,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getToolsIA;
  }, [client]);
};

export default UseGetToolsIA;

const GET_TOOLS_IA = gql`
  query getToolsIA {
  getToolsIA {
    id
    title
  }
}
`;
