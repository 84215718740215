import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./toolsIAForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Form, Input, notification, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FormsToolsIA, initialValuesObj } from "./ToolsIAForm.types";
import {
  FormsTypeInput,
  ToolsIAInput,
} from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { UploadImageFile } from "../../UploadFile";
import { ContentTypeSelect } from "./ContentComponents/ContentTypeSelect";
import { FileImageCoverProps } from "../QuizzesForm/utils";
import { useDispatch } from "react-redux";
import { useToolsIA } from "../../../hooks/useToolsIA";
import UseGetToolsIAById from "../../../api/useGetToolsIAById";
import UseCreateToolsIA from "../../../api/useCreateToolsIA";
import UseUpdateToolsIA from "../../../api/useUpdateToolsIA";
import { getToolsIA_getToolsIA } from "../../../__generated__/gql-types/getToolsIA";

const NewToolsIAForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toolsIA, loading: isLoadingToolsIA } = useToolsIA();
  const toolsIA_id = location?.state as string;
  const getToolsIAById = UseGetToolsIAById();
  const createToolsIA = UseCreateToolsIA();
  const updateToolsIA = UseUpdateToolsIA();
  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<ToolsIAInput>(initialValuesObj);
  const [content, setContent] = useState<FormsToolsIA[]>([]);
  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");

  const fetchInfo = async () => {
    try {
      if (toolsIA_id) {
        const data = await getToolsIAById({ getToolsIaByIdId: toolsIA_id });

        if (data?.id) {
          setInitialValues({
            cover: data?.cover ?? "",
            description: data?.description ?? "",
            forms: data?.forms ?? [],
            title: data?.title ?? "",
            prompt: data?.prompt ?? "",
          });

          if (data && data.forms && data.forms.length > 0) {
            const forms = data.forms as FormsToolsIA[];
            setContent(forms);
          }

          setfilenameCover({
            id: data.cover!.split(".")[0],
            ext: data.cover!.split(".")[1],
          });
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [loading, isLoadingToolsIA]);

  const handleSubmit = async (formValues: any) => {
    const updatedContent: FormsTypeInput[] = content.map((item, index) => {
      return {
        ...item,
        section: index + 2,
      };
    });

    let toolsIAInput: ToolsIAInput = {
      cover:
        filenameCover && filenameCover.id !== ""
          ? `${filenameCover.id}.${filenameCover.ext}`
          : "",
      title: initialValues.title,
      description: initialValues.description,
      forms: updatedContent,
      prompt: initialValues.prompt,
    };

    setloading(true);
    try {
      if (toolsIA_id && toolsIA_id.length > 0) {
        const updateResponse = await updateToolsIA({
          toolsIaInput: toolsIAInput,
          updateToolsIaId: `${toolsIA_id}`,
        });
        updateResponse && updatedNotification();

        let index = toolsIA.findIndex(
          (toolIA) => toolIA.id === updateResponse.data.updateToolsIA.id
        );
        let toolsIAUpdated = [...toolsIA];

        toolsIAUpdated[index] = updateResponse.data
          .updateToolsIA as unknown as getToolsIA_getToolsIA;
        dispatch({ type: "data/setToolsIA", payload: toolsIAUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createToolsIA({
          toolsIaInput: toolsIAInput,
        });
        createResponse && successNotification();
        let newToolsIA = [];
        let newToolIA = createResponse.data
          ?.createToolsIA as unknown as getToolsIA_getToolsIA;
        newToolsIA = [newToolIA, ...toolsIA];
        dispatch({ type: "data/setToolsIA", payload: newToolsIA });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingToolsIA) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {toolsIA_id && toolsIA_id.length > 0 ? (
        <h1>Editar Herramienta IA</h1>
      ) : (
        <h1>Nueva Herramienta IA</h1>
      )}

      <Form className={styles.formContainer} onFinish={handleSubmit}>
        <Row style={{ marginLeft: 20, marginTop: 20 }}>
          <Col span={20}>
            <label htmlFor="asignatura">Título</label>
            <Input
              name="title"
              type="text"
              style={{ borderRadius: 5 }}
              value={initialValues.title!}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInitialValues({
                  ...initialValues,
                  title: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <Row style={{ marginLeft: 20, marginTop: 20 }}>
          <Col span={20}>
            <label htmlFor="asignatura">Resumen de Herramienta</label>
            <Input
              name="description"
              type="text"
              style={{ borderRadius: 5 }}
              value={initialValues.description ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInitialValues({
                  ...initialValues,
                  description: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <UploadImageFile
            title="Cover"
            className={`${styles.uploadFile}`}
            typeFile="quiz"
            folder="covers"
            widthCover={300}
            heightCover={200}
            image={initialValues.cover ?? ""}
            filenameCover={filenameCover}
            setfilenameCover={setfilenameCover}
            urlImageCover={urlImageCover}
            setUrlImageCover={setUrlImageCover}
          />
        </div>

        <ContentTypeSelect
          toolsIA_id={toolsIA_id}
          content={content}
          setContent={setContent}
        />

        <Row>
          <Col span={23} style={{ marginTop: 10, marginLeft: 30 }}>
            <label htmlFor="asignatura">Prompt</label>
            <TextArea
              name="message"
              title="Mensaje"
              rows={10}
              style={{ borderRadius: 5 }}
              value={initialValues.prompt ?? ""}
              onChange={(e) => {
                setInitialValues({
                  ...initialValues,
                  prompt: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <Buttons
          handleClick={() => {
            navigate(-1);
          }}
        />
      </Form>
    </div>
  );
};

export default NewToolsIAForm;
