import React, { ChangeEvent, useState } from "react";
import styles from "./superdemo.module.css";
import { Field, Form, Formik, FormikProps } from "formik";
import { Buttons } from "../../components";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import axios from "axios";
import {
  environment,
  resolveEnvSuperDemo,
} from "../../configuration/environment";
import { notification } from "antd";

const SuperDemo = () => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    isPrimaria: true,
  });
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState<any[]>([]);

  const handleSubmit = async (formValues: any) => {
    if (initialValues.email.length > 0) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${environment.superDemoUrl}/super-demo`,
          {
            email: initialValues.email,
            env: resolveEnvSuperDemo(),
            school_type: initialValues.isPrimaria ? "primary" : "secondary",
          }
        );
        response.data?.duplicateProf &&
          setEmails(response?.data?.duplicateProf);
        response.data?.duplicateProf && successNotification();
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        errorNotification();
      }
    } else {
      notification.info({ message: "Debes completar el campo Email." });
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Super Demo</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <label htmlFor="">
                  {" "}
                  Ingrese el usuario profesor que va a copiar
                </label>

                <div className={styles.containerInput}>
                  <Field
                    errors={errors}
                    touched={touched}
                    value={initialValues.email}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        email: e.target.value,
                      });
                    }}
                    name="email"
                    title="Email"
                    type="text"
                  />
                  <div>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.isPrimaria}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        !initialValues.isPrimaria &&
                          setInitialValues({
                            ...initialValues,
                            isPrimaria: e.target.checked,
                          });
                      }}
                    />
                    <label
                      htmlFor="program_2017"
                      style={{ marginLeft: "10px" }}
                    >
                      Mostrar Primaria
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={!initialValues.isPrimaria}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        initialValues.isPrimaria &&
                          setInitialValues({
                            ...initialValues,
                            isPrimaria: !e.target.checked,
                          });
                      }}
                    />
                    <label
                      htmlFor="program_2017"
                      style={{ marginLeft: "10px" }}
                    >
                      Mostrar Secundaria
                    </label>
                  </div>
                </div>

                <label htmlFor=""> Resultados finales:</label>
                <br />
                {emails.length > 0 &&
                  emails.map((user) => {
                    return (
                      <>
                        <div>
                          {user.email} | {user.role}
                        </div>
                        <br />
                      </>
                    );
                  })}

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SuperDemo;
