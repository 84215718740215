import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { vocabularyRetrieveAllVocabularyWords } from "../../__generated__/gql-types/vocabularyRetrieveAllVocabularyWords";

const UseGetWords = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<vocabularyRetrieveAllVocabularyWords> =
      await client.query({
        query: GET_WORDS,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.vocabularyRetrieveAllVocabularyWords;
  }, [client]);
};

export default UseGetWords;

const GET_WORDS = gql`
  query vocabularyRetrieveAllVocabularyWords {
    vocabularyRetrieveAllVocabularyWords {
      id
      gifFileId
      spanishWord
      englishWord
      synonyms
      wordType
      clue {
        id
        example
        phrase
        type
      }
      createdAt
      updatedAt
    }
  }
`;
