import React, { ChangeEvent, useEffect } from "react";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ContentIterationComponent } from "./ContentIteration/ContentIterationComponent";
import { FormsToolsIA } from "../ToolsIAForm.types";

interface ContentTypeSelecProps {
  toolsIA_id: string | undefined;
  content: FormsToolsIA[];
  setContent: React.Dispatch<React.SetStateAction<FormsToolsIA[]>>;
}

export const ContentTypeSelect = ({
  toolsIA_id,
  content,
  setContent,
}: ContentTypeSelecProps) => {
  useEffect(() => {
    if (!toolsIA_id && content.length === 0) {
      setContent([
        ...content,
        {
          example_text: "",
          label: "",
          number_of_lines: 1,
          section: 2,
        },
      ]);
    }
  }, []);

  const handleAddContent = () => {
    setContent([
      ...content,
      {
        example_text: "",
        label: "",
        number_of_lines: 1,
        section: content.length + 2,
      },
    ]);
  };

  const handleRemoveContent = (index: number) => {
    let updatedContents = content.filter((q, i) => i !== index);
    setContent(updatedContents);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    indexContent: number
  ) => {
    const { name, value } = event.target;

    setContent((prevContent) => {
      const updatedContent = prevContent.map((item, index) =>
        index === indexContent
          ? {
              ...item,
              [name]: name === "number_of_lines" ? parseInt(value, 10) : value,
            }
          : item
      );
      return updatedContent;
    });
  };

  return (
    <>
      {content.map((item, indexContent) => {
        return (
          <div key={indexContent}>
            <ContentIterationComponent
              keyGenerator={`${indexContent}`}
              item={item}
              content={content}
              indexContent={indexContent}
              handleChange={handleChange}
              buttonAddNewContent={
                <span>
                  <a
                    style={{ fontSize: 22, padding: 10 }}
                    onClick={handleAddContent}
                  >
                    <PlusCircleOutlined></PlusCircleOutlined>
                  </a>
                </span>
              }
              buttonDeleteContent={
                <span>
                  <a
                    style={{ fontSize: 22, padding: 10 }}
                    onClick={() => {
                      handleRemoveContent(indexContent);
                    }}
                  >
                    <DeleteOutlined></DeleteOutlined>
                  </a>
                </span>
              }
            />
          </div>
        );
      })}
    </>
  );
};
