import React, { useState } from "react";
import styles from "./reports.module.css";
import { Form, Formik, FormikProps } from "formik";
import { Buttons } from "../../components";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import { useSchools } from "../../hooks/useSchools";
import { GetSchools_getSchools } from "../../__generated__/gql-types/GetSchools";
import { Select, DatePicker, Col } from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import { environment } from "../../configuration/environment";

const { RangePicker } = DatePicker;

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [schoolSelected, setSchoolSelected] = useState<number>(-1);
  // const [trimesterSelected, setTrimesterSelected] = useState<number>(-1);
  const { loading: loadingSchools, schools: schoolsData } = useSchools();
  const [dates, setDates] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: "",
    end_date: "",
  });

  const handleChangeSchool = (value: string[]) => {
    if (value && value.length > 0 && value[0].includes("-")) {
      const schoolNumber = Number(value[0].split("-")[0]);
      setSchoolSelected(schoolNumber);
    } else {
      setSchoolSelected(-1);
    }
  };

  // const handleChangeTrimester = (value: string[]) => {
  //   if (value.length > 0) {
  //     const trimesterNumber = Number(value[0]);
  //     setTrimesterSelected(trimesterNumber);
  //   } else {
  //     setTrimesterSelected(-1);
  //   }
  // };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // generar reportes
      if (
        schoolSelected !== -1 &&
        dates.start_date.length > 0 &&
        dates.end_date.length > 0
      ) {
        const response = await axios.post(
          `${environment.reportsUrl}/allReports`,
          {
            school_id: schoolSelected,
            start_date: dates.start_date,
            end_date: dates.end_date,
          }
        );

        console.log(response.data);
        successNotification();
      } else {
        errorNotification();
      }

      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      errorNotification();
    }
  };

  if (loading || loadingSchools) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Generador de Reportes por Escuela</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={{}}>
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <div style={{ marginTop: 20, marginBottom: 20, fontSize: 20 }}>
                  Carpeta de reportes: {"   "}
                  <a
                    target="_blank"
                    href="https://drive.google.com/drive/folders/1noaK5gJsvY2DLU_d86dYxfIxjIdXI_Me?usp=drive_link"
                  >
                    Enlace
                  </a>
                </div>

                {/* School Select */}
                <div style={{ marginBottom: 20 }}>
                  <label htmlFor="school">Escuela (sólo 1)</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    onChange={handleChangeSchool}
                    defaultValue={undefined}
                  >
                    {schoolsData?.map(
                      (school: GetSchools_getSchools, index: number) => {
                        return (
                          <Option
                            value={`${school.id}-${school.name}`}
                            key={`${index}-${new Date()}`}
                          >
                            {school.name}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </div>

                {/* Dates */}
                <Col
                  span={12}
                  style={{
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label htmlFor="school">Seleccione un rango de fechas</label>
                  <RangePicker
                    onCalendarChange={(value) => {
                      const start_date = value?.[0]?.format("YYYY-MM-DD");
                      const end_date = value?.[1]?.format("YYYY-MM-DD");

                      if (start_date && end_date) {
                        setDates({
                          start_date,
                          end_date,
                        });
                      }
                    }}
                  />
                </Col>

                {/* School Select */}
                {/* <div style={{ marginBottom: 30 }}>
                  <label htmlFor="school">Trimestre (sólo 1)</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    onChange={handleChangeTrimester}
                    defaultValue={undefined}
                  >
                    {[1, 2, 3]?.map((trimester: number, index: number) => {
                      return (
                        <Option
                          value={`${trimester}`}
                          key={`${index}-${new Date()}`}
                        >
                          Trimestre {trimester}
                        </Option>
                      );
                    })}
                  </Select>
                </div> */}

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Reports;
