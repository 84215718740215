import { ToolsIAInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: ToolsIAInput = {
  cover: "",
  description: "",
  title: "",
  forms: [],
  prompt: "",
};

export interface FormsToolsIA {
  label: string;
  example_text: string;
  number_of_lines: number;
  section: number;
}
