import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetSchoolById } from "../__generated__/gql-types/GetSchoolById";

const UseGetSchoolById = (schoolId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetSchoolById> = await client.query({
      query: GET_SCHOOL_BY_ID,
      fetchPolicy: "no-cache",
      variables: {
        schoolId,
      },
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getSchoolById;
  }, [client]);
};

export default UseGetSchoolById;

const GET_SCHOOL_BY_ID = gql`
  query GetSchoolById($schoolId: ID!) {
    getSchoolById(schoolId: $schoolId) {
      id
      demo
      cct
      name
      city
      publish
      show_sep_pages
      show_grades_on_base_100
      view_project_generator
      view_literacy
      view_NEM
      view_NEM_student
      view_2017_student
      view_reading_spanish
      view_reading_english
      view_english
      view_english_2526
      view_shop
      view_dynamics
      view_library_english
      view_library_spanish
      view_2526_student
      view_2526
      is_active
      show_tournament
      show_primary
      show_secondary
      show_vocabulary
      excluded_themes {
        id
        name
      }
      excluded_axes {
        id
        name
      }
    }
  }
`;
