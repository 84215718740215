interface EnvironmentType {
  apiGraphQLURL: string;
  urlImages: string;
  urlVideos: string;
  reportsUrl: string;
  backendUrl: string;
  superDemoUrl: string;
}

const resolveEnvironment = (): string => {
  if (`${process.env.REACT_APP_NODE_ENV}` === "local") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_LOCAL}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "development") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_DEV}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "production") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_PROD}`;
  }

  return "";
};

export const resolveEnvSuperDemo = () => {
  if (`${process.env.REACT_APP_NODE_ENV}` === "local") {
    return "dev";
  }
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "development" ||
    `${process.env.REACT_APP_NODE_ENV}` === "dev"
  ) {
    return "dev";
  }
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    return "prod";
  }

  return "prod";
};

const resolveBackendUrl = (): string => {
  if (`${process.env.REACT_APP_NODE_ENV}` === "local") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_LOCAL}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "development") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_DEV}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "production") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_PROD}`;
  }

  return "";
};

export const environment: EnvironmentType = {
  apiGraphQLURL: resolveEnvironment(),
  urlImages: `${process.env.REACT_APP_URL_IMAGES}` || "",
  urlVideos: `${process.env.REACT_APP_URL_VIDEOS}` || "",
  reportsUrl:
    `${process.env.REACT_APP_URL_REPORTS_SERVICE}` ||
    "https://luca2-service-reports-1057825181867.us-west1.run.app",
  superDemoUrl:
    `${process.env.REACT_APP_URL_SUPER_DEMO_SERVICE}` ||
    "https://luca2-service-super-demo-1057825181867.us-west1.run.app",
  backendUrl: resolveBackendUrl(),
};
