import * as Yup from "yup";
import { VocabularyWordInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: VocabularyWordInput = {
  clue: {
    example: null,
    type: "GRAPPED",
  },
  englishWord: "",
  gifFileId: "",
  spanishWord: "",
  synonyms: [],
  wordType: "",
};

export const NewProgramsSchema = Yup.object().shape({
  englishWord: Yup.string().required("El título es requerido."),
  spanishWord: Yup.string().required("La palabra en español es requerida."),
  // description: Yup.string().required("La descripción es requerida."),
});
