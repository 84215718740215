import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import UseGetToolsIA from "../api/useGetToolsIA";
import useDeleteToolsIA from "../api/useDeleteToolsIA";
import { TOOLS_IA } from "../store/selectors";
import { getToolsIA_getToolsIA } from "../__generated__/gql-types/getToolsIA";

export const useToolsIA = () => {
  const dispatch = useDispatch();
  const toolsIASelector = useSelector(TOOLS_IA);
  const [loading, setLoading] = useState(false);
  const [toolsIA, setToolsIA] = useState<getToolsIA_getToolsIA[]>([]);

  const deleteToolsIAMutation = useDeleteToolsIA();
  const getToolsIA = UseGetToolsIA();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (toolsIASelector?.length > 0) {
        setToolsIA(toolsIASelector);
      } else {
        const toolsIAResponse = await getToolsIA();
        const toolsIAData = toolsIAResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        toolsIAData && setToolsIA(toolsIAData);
        dispatch({ type: "data/setToolsIA", payload: toolsIAData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteToolsIA = async (idToolsIA: string) => {
    setLoading(true);
    try {
      const response = await deleteToolsIAMutation({
        deleteToolsIaId: idToolsIA.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La Herramienta IA fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedLiteracies = toolsIA.filter(
          (toolIA) => toolIA.id !== idToolsIA
        );
        setToolsIA(updatedLiteracies);
        dispatch({ type: "data/setToolsIA", payload: updatedLiteracies });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, toolsIA, deleteToolsIA };
};
