import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import {
  deleteToolsIA,
  deleteToolsIAVariables,
} from "../__generated__/gql-types/deleteToolsIA";

const useDeleteToolsIA = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteToolsIAVariables) => {
      return await client.mutate<deleteToolsIA>({
        mutation: DLETE_TOOLS_IA,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteToolsIA;

const DLETE_TOOLS_IA = gql`
  mutation deleteToolsIA($deleteToolsIaId: ID!) {
    deleteToolsIA(id: $deleteToolsIaId) {
      id
      title
    }
  }
`;
