import React, { ChangeEvent } from "react";
import { Col, Input, Row } from "antd";
import { FormsToolsIA } from "../../ToolsIAForm.types";

interface ContentIterationComponentProps {
  keyGenerator: string;
  buttonAddNewContent: JSX.Element;
  buttonDeleteContent: JSX.Element;
  item: FormsToolsIA;
  content: FormsToolsIA[];
  indexContent: number;
  handleChange: (
    event: ChangeEvent<HTMLInputElement>,
    indexContent: number
  ) => void;
}

export const ContentIterationComponent = ({
  keyGenerator,
  buttonAddNewContent,
  buttonDeleteContent,
  item,
  content,
  indexContent,
  handleChange,
}: ContentIterationComponentProps) => {
  return (
    <div
      key={keyGenerator}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 20,
        padding: 20,
        border: "1px solid gray",
        borderRadius: 20,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <label htmlFor="theme">
          Seleciona el tipo de Contenido #{indexContent + 2}
        </label>
        {content.length - 1 === indexContent && buttonAddNewContent}
        {content.length > 1 && buttonDeleteContent}
      </div>

      <Row>
        <Col span={20} style={{ marginTop: 10 }}>
          <label htmlFor="asignatura">{`Título (CAMPO ${
            indexContent + 2
          })`}</label>
          <Input
            name="label"
            type="text"
            style={{ borderRadius: 5 }}
            value={item.label}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, indexContent);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={20} style={{ marginTop: 10 }}>
          <label htmlFor="asignatura">Ejemplo de Texto</label>
          <Input
            name="example_text"
            type="text"
            style={{ borderRadius: 5 }}
            value={item.example_text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, indexContent);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ marginTop: 10 }}>
          <label htmlFor="asignatura">Número de líneas</label>
          <Input
            name="number_of_lines"
            type="number"
            style={{ borderRadius: 5 }}
            value={item.number_of_lines}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, indexContent);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
