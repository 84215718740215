import { notification } from "antd";

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description:
      "Generando reportes!! Esperá por favor a que todo se cree correctamente.",
    placement: "top",
    duration: 3,
  });

export const errorNotification = () =>
  notification["error"]({
    message: "Error",
    description:
      "Se ha producido un error, por favor revise la carpeta donde se crearon los reportes.",
    placement: "top",
    duration: 3,
  });
