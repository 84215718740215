import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createToolsIA, createToolsIAVariables } from "../__generated__/gql-types/createToolsIA";

const UseCreateToolsIA = () => {
  const client = useApolloClient();
  return useCallback(
    async (toolsIAInput: createToolsIAVariables) => {
      return await client.mutate<createToolsIA>({
        mutation: CREATE_TOOLS_IA,
        fetchPolicy: "no-cache",
        variables: toolsIAInput,
      });
    },
    [client]
  );
};

export default UseCreateToolsIA;

const CREATE_TOOLS_IA = gql`
  mutation createToolsIA($toolsIaInput: ToolsIAInput) {
    createToolsIA(toolsIAInput: $toolsIaInput) {
      id
      title
    }
  }
`;
