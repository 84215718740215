import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROGRAMS } from "../store/selectors";
import { notification } from "antd";
import UseGetWords from "../api/vocabulary/useGetWords";
import { vocabularyRetrieveAllVocabularyWords_vocabularyRetrieveAllVocabularyWords } from "../__generated__/gql-types/vocabularyRetrieveAllVocabularyWords";
import UseCreateVocabularyLesson from "../api/vocabulary/useCreateVocabularyLesson";
import { VocabularyInput } from "../__generated__/gql-types/globalTypes";

export const useVocabulary = () => {
  const dispatch = useDispatch();
  const programsSelector = useSelector(PROGRAMS);
  const [loading, setLoading] = useState(false);
  const [words, setWords] = useState<
    vocabularyRetrieveAllVocabularyWords_vocabularyRetrieveAllVocabularyWords[]
  >([]);

  const createVocabularyLessonMutation = UseCreateVocabularyLesson();
  const getWords = UseGetWords();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      const programsResponse = await getWords();
      programsResponse &&
        setWords(
          programsResponse.filter(
            (word) => word !== null
          ) as vocabularyRetrieveAllVocabularyWords_vocabularyRetrieveAllVocabularyWords[]
        );
      // dispatch({ type: "data/setPrograms", payload: programsData });

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const createVocabularyLesson = async (itemsVocabulary: VocabularyInput) => {
    try {
      const response = await createVocabularyLessonMutation({
        vi: itemsVocabulary,
      });
      return response;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return {
    loading,
    words,
    createVocabularyLesson,
  };
};
