import * as Yup from "yup";
import { SchoolInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: SchoolInput = {
  cct: "",
  name: "",
  city: "",
  demo: false,
  show_sep_pages: false,
  show_grades_on_base_100: false,
  view_english: false,
  view_english_2526: false,
  view_literacy: false,
  view_NEM: false,
  view_NEM_student: false,
  view_2017_student: false,
  view_project_generator: false,
  view_shop: false,
  view_dynamics: false,
  view_library_english: false,
  view_library_spanish: false,
  view_2526: false,
  view_2526_student: false,
  show_tournament: true,
  show_primary: true,
  show_secondary: false,
  show_vocabulary: false,
  excluded_axes: [],
  excluded_themes: [],
};

export const SchoolsFormSchema = Yup.object().shape({
  cct: Yup.string().required("CCT es requerido."),
  name: Yup.string().required("El nombre es requerido."),
  city: Yup.string().required("La ciudad es requerida."),
});
