import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { vocabularyRegisterVocabularyWordVariables } from "../../__generated__/gql-types/vocabularyRegisterVocabularyWord";

const UseCreateWords = () => {
  const client = useApolloClient();
  return useCallback(
    async (programInput: vocabularyRegisterVocabularyWordVariables) => {
      return await client.mutate({
        mutation: CREATE_VOCABULARY,
        fetchPolicy: "no-cache",
        variables: programInput,
      });
    },
    [client]
  );
};

export default UseCreateWords;

const CREATE_VOCABULARY = gql`
  mutation vocabularyRegisterVocabularyWord(
    $vocabularyWordInput: VocabularyWordInput!
  ) {
    vocabularyRegisterVocabularyWord(
      vocabularyWordInput: $vocabularyWordInput
    ) {
      id
      gifFileId
      spanishWord
      englishWord
      synonyms
      wordType
      clue {
        id
        example
        type
        phrase
      }
      createdAt
      updatedAt
    }
  }
`;
