import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateToolsIAVariables } from "../__generated__/gql-types/updateToolsIA";

const UseUpdateLiteracy = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateToolsIAVariables) => {
      return await client.mutate({
        mutation: UPDATE_TOOLS_IA,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateLiteracy;

const UPDATE_TOOLS_IA = gql`
  mutation updateToolsIA($updateToolsIaId: ID!, $toolsIaInput: ToolsIAInput) {
    updateToolsIA(id: $updateToolsIaId, toolsIAInput: $toolsIaInput) {
      id
      title
    }
  }
`;
