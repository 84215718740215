import { FC, useEffect, useState } from "react";
import { notification, Select } from "antd";
import styles from "./vocabularyForm.module.css";
import { Buttons, InputField } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { NewProgramsSchema, initialValuesObj } from "./VocabularyForm.types";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { VocabularyWordInput } from "./../../../__generated__/gql-types/globalTypes";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useDispatch } from "react-redux";
import { useVocabulary } from "../../../hooks/useVocabulary";
import { vocabularyRetrieveAllVocabularyWords_vocabularyRetrieveAllVocabularyWords as wordProps } from "../../../__generated__/gql-types/vocabularyRetrieveAllVocabularyWords";
import UseCreateWords from "../../../api/vocabulary/useCreateWords";
import UseUpdateWords from "../../../api/vocabulary/useUpdateWords";
import { UploadImageFile } from "../../UploadFile";
import { FileImageCoverProps } from "../QuizzesForm/utils";
import { vocabularyRegisterVocabularyWord_vocabularyRegisterVocabularyWord } from "../../../__generated__/gql-types/vocabularyRegisterVocabularyWord";

const { Option } = Select;

const VocabularyForm: FC = () => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const { loading: isLoadingPrograms, words } = useVocabulary();
  const [initialValues, setInitialValues] =
    useState<VocabularyWordInput>(initialValuesObj);
  const createWords = UseCreateWords();
  const updateWords = UseUpdateWords();
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state as wordProps;
  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");

  const fetchInfo = async () => {
    setloading(true);
    console.log(data);
    try {
      if (data && data.id) {
        setInitialValues({
          clue: {
            example: data.clue?.example,
            type: data.clue?.type,
            phrase: data.clue?.phrase,
          },
          englishWord: data.englishWord,
          gifFileId: data?.gifFileId ?? "",
          spanishWord: data.spanishWord,
          synonyms: data.synonyms,
          wordType: data.wordType,
        });
        data.gifFileId &&
          setfilenameCover({
            id: data.gifFileId?.split(".")[0],
            ext: data.gifFileId?.split(".")[1],
          });
      }

      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };
  const handleChangeSynonyms = (values: string[]) => {
    initialValues.synonyms = values;
    console.log(values);
    setInitialValues({ ...initialValues, synonyms: values });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleSubmit = async (values: VocabularyWordInput) => {
    let LocalWord: VocabularyWordInput = {
      ...values,
      gifFileId:
        filenameCover && filenameCover.id !== ""
          ? `${filenameCover.id}.${filenameCover.ext}`
          : "",
      wordType: values.wordType,
    };
    console.log({ LocalWord });

    setloading(true);
    try {
      console.log("values", values);
      if (data?.id) {
        const response = await updateWords(
          {
            vocabularyWordInput: LocalWord,
          },
          data.id
        );
        response.data.updateProgram && updatedNotification();
        let index = words.findIndex((program) => program.id === data.id);
        let programsUpdated = [...words];
        programsUpdated[index] = response.data
          .updateProgram as unknown as vocabularyRegisterVocabularyWord_vocabularyRegisterVocabularyWord;
        // dispatch({ type: "data/setPrograms", payload: programsUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createWords({
          vocabularyWordInput: LocalWord,
        });
        createResponse.data && successNotification();
        // let newPrograms = [];
        // let newProgram = createResponse.data
        //   .createProgram as unknown as GetPrograms_getPrograms;
        // newPrograms = [newProgram, ...words];
        // dispatch({ type: "data/setPrograms", payload: newPrograms });
        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      setloading(false);
      errorNotification();
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Vocabulario inglés </h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewProgramsSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              <InputField
                errors={errors}
                touched={touched}
                name="englishWord"
                title="PALABA EN INGLES - Titulo"
                value={initialValues.englishWord || ""}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    englishWord: e.target.value,
                  });
                }}
                type="text"
              />
              <InputField
                errors={errors}
                touched={touched}
                name="spanishWord"
                title="PALABA EN ESPAÑOL"
                value={initialValues.spanishWord}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    spanishWord: e.target.value,
                  });
                }}
                type="text"
              />
              <InputField
                errors={errors}
                touched={touched}
                name="clue"
                title="PALABA CON _ - Pista"
                value={initialValues.clue?.phrase || ""}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    clue: {
                      ...initialValues.clue,
                      phrase: e.target.value,
                    },
                  });
                }}
                type="text"
              />

              <label htmlFor="course">TIPO DE PALABRA</label>
              <Select
                value={initialValues.wordType ?? "videolesson"}
                className={styles.formFieldSelect}
                style={{ marginBottom: 20 }}
                onChange={(value: string) => {
                  setInitialValues({
                    ...initialValues,
                    wordType: value,
                  });
                  // setTypeLessonSelected(value);
                }}
                options={[
                  { value: "adjetivo", label: "Adjetivo" },
                  { value: "pronombre", label: "Pronombre" },
                  { value: "sustantivo", label: "Sustantivo" },
                  { value: "verbo", label: "Verbo" },
                  { value: "adverbio", label: "Adverbio" },
                  { value: "preposición", label: "Preposición" },
                  { value: "conjunción", label: "Conjunción" },
                  { value: "interjección", label: "Interjección" },
                ]}
              />

              <ErrorMessage
                name="wordType"
                component="div"
                className={styles.error}
              />

              <label htmlFor="axes">SINONIMOS</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                onChange={handleChangeSynonyms}
                value={initialValues.synonyms}
              >
                {initialValues.synonyms?.map((axe, index) => (
                  <Option value={axe} key={index}>
                    {axe}
                  </Option>
                ))}
              </Select>
              <ErrorMessage
                name="axes"
                component="div"
                className={styles.error}
              />

              <InputField
                errors={errors}
                touched={touched}
                name="clue"
                title="FRASE DE USO"
                value={initialValues.clue?.example || ""}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    clue: { ...initialValues.clue, example: e.target.value },
                  });
                }}
                type="text"
              />

              <UploadImageFile
                title="Vocabulary"
                className={`${styles.uploadFile}`}
                typeFile="quiz"
                folder="vocabulary"
                widthCover={300}
                heightCover={200}
                image={initialValues?.gifFileId ?? ""}
                filenameCover={filenameCover}
                setfilenameCover={setfilenameCover}
                urlImageCover={urlImageCover}
                setUrlImageCover={setUrlImageCover}
              />

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VocabularyForm;
