import { createSlice } from "@reduxjs/toolkit";

export type DataState = {
  videos: any[];
  courses: any[];
  quizzes: any[];
  lessons: any[];
  programs: any[];
  users: any[];
  subjects: any[];
  schools: any[];
  axes: any[];
  themes: any[];
  tags: any[];
  classrooms: any[];
  grades: any[];
  essays: any[];
  literacies: any[];
  listenings: any[];
  aiConversations: any[];
  clothesAndAccessories: any[];
  notifications: any[];
  toolsIA: any[];
};

const initialState: DataState = {
  videos: [],
  courses: [],
  quizzes: [],
  lessons: [],
  programs: [],
  users: [],
  subjects: [],
  schools: [],
  axes: [],
  themes: [],
  tags: [],
  classrooms: [],
  grades: [],
  essays: [],
  literacies: [],
  listenings: [],
  aiConversations: [],
  clothesAndAccessories: [],
  notifications: [],
  toolsIA: [],
};

const data = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    setVideos: (state, action) => {
      return {
        ...state,
        videos: action.payload,
      };
    },
    setCourses: (state, action) => {
      return {
        ...state,
        courses: action.payload,
      };
    },
    setQuizzes: (state, action) => {
      return {
        ...state,
        quizzes: action.payload,
      };
    },
    setLessons: (state, action) => {
      return {
        ...state,
        lessons: action.payload,
      };
    },
    setPrograms: (state, action) => {
      return {
        ...state,
        programs: action.payload,
      };
    },
    setUsers: (state, action) => {
      return {
        ...state,
        users: action.payload,
      };
    },
    setSubjects: (state, action) => {
      return {
        ...state,
        subjects: action.payload,
      };
    },
    setSchools: (state, action) => {
      return {
        ...state,
        schools: action.payload,
      };
    },
    setAxes: (state, action) => {
      return {
        ...state,
        axes: action.payload,
      };
    },
    setThemes: (state, action) => {
      return {
        ...state,
        themes: action.payload,
      };
    },
    setTags: (state, action) => {
      return {
        ...state,
        tags: action.payload,
      };
    },
    setClassroom: (state, action) => {
      return {
        ...state,
        classrooms: action.payload,
      };
    },
    setGrades: (state, action) => {
      return {
        ...state,
        grades: action.payload,
      };
    },
    setEssays: (state, action) => {
      return {
        ...state,
        essays: action.payload,
      };
    },
    setLiteracies: (state, action) => {
      return {
        ...state,
        literacies: action.payload,
      };
    },
    setToolsIA: (state, action) => {
      return {
        ...state,
        toolsIA: action.payload,
      };
    },
    setListenings: (state, action) => {
      return {
        ...state,
        listenings: action.payload,
      };
    },
    setAIConversations: (state, action) => {
      return {
        ...state,
        aiConversations: action.payload,
      };
    },
    setClothesAndAccessories: (state, action) => {
      return {
        ...state,
        clothesAndAccessories: action.payload,
      };
    },
    setNotifications: (state, action) => {
      return {
        ...state,
        notifications: action.payload,
      };
    },
  },
});

export default data;
