import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./vocabulary.module.css";
import { Button, Space, Table, Modal, notification, Input } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { useVocabulary } from "../../hooks/useVocabulary";
import { vocabularyRetrieveAllVocabularyWords_vocabularyRetrieveAllVocabularyWords as wordsProps } from "../../__generated__/gql-types/vocabularyRetrieveAllVocabularyWords";

const { Search } = Input;

const Vocabulary: FC = () => {
  const { loading: isLoading, words } = useVocabulary();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idProgram, setIdProgram] = useState<string>("");
  const [dataSource, setdataSource] = useState<wordsProps[] | null>([]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdProgram(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      // await deleteProgram(idProgram);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El programa no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<wordsProps> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      align: "center",
      title: "Título",
      dataIndex: "englishWord",
      key: "englishWord",
    },
    {
      align: "center",
      title: "Palabra",
      dataIndex: "spanishWord",
      key: "spanishWord",
    },

    {
      title: "Action",
      key: "action",
      width: 200,
      render: (record) => {
        const records = record;
        return (
          <Space>
            <Link to={`/vocabulary/${record.id}`} state={records}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            {/* <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button> */}
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(words);
    } else {
      if (dataSource) {
        const filteredData: wordsProps[] = dataSource.filter((data) =>
          data.englishWord?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  useEffect(() => {
    setdataSource(words);
  }, [words]);

  return (
    <div className={styles.container}>
      <h1>Vocabulario Ingles</h1>
      <div className={styles.programsContent}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar por palabra"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Link to="/vocabulary/new">
          <Button type="primary" danger>
            Nuevo Palabra
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar La palabra"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la palabra?</p>
      </Modal>
    </div>
  );
};

export default Vocabulary;
