import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./lessons.module.css";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  notification,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { GetLessons_getLessons } from "./../../__generated__/gql-types/GetLessons";
import { useLessons } from "../../hooks/useLessons";

const { Search } = Input;

const Lessons: FC = () => {
  const { lessons, deleteLesson, loading: isLoading } = useLessons();
  const [dataSource, setdataSource] = useState<GetLessons_getLessons[] | null>(
    []
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idLesson, setIdLesson] = useState<string>("");

  const columns: ColumnsType<GetLessons_getLessons> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "  Título",
      dataIndex: "title",
      key: "title",
    },
    {
      align: "center",
      title: "Grado",
      dataIndex: "grade",
      key: "grade",
      render: (grade) => {
        return <div className={styles.textDraw}>{grade.name}</div>;
      },
    },
    {
      title: "Tipo",
      dataIndex: "lesson_type",
      key: "lesson_type",
      render: (lesson_type) => {
        if (lesson_type === "videolesson") {
          return "Video Lección";
        } else if (lesson_type === "slides") {
          return "Diapositivas";
        } else if (lesson_type === "book_pages") {
          return "Páginas del libro";
        } else if (lesson_type === "classroomguide") {
          return "Guión de clase";
        } else if (lesson_type === "evaluation") {
          return "Evaluación";
        } else if (lesson_type === "redaction") {
          return "Redacción";
        } else if (lesson_type === "literacy") {
          return "Lectoescritura";
        } else if (lesson_type === "listening") {
          return "Listening";
        } else if (lesson_type === "vocabulary") {
          return "Vocabulary";
        } else if (lesson_type === "conversation") {
          return "Conversación IA";
        } else if (lesson_type === "audio_exam") {
          return "Examen de Audio";
        } else if (lesson_type === "writing_exam") {
          return "Examen de Escritura";
        } else if (lesson_type === "manipulative_dynamics") {
          return "Dinámicas Manipulativos";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const records = record;
        return (
          <Space size="middle">
            <Link to={`/lessons/${record.id}`} state={records}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdLesson(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setdataSource(lessons);
  }, [lessons]);

  const handleOk = async () => {
    try {
      await deleteLesson(idLesson);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "La leccion no pudo ser eliminada!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(lessons);
    } else {
      if (dataSource) {
        const filteredData: GetLessons_getLessons[] = dataSource.filter(
          (data) => data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(lessons);
    } else {
      if (dataSource) {
        const filteredData: GetLessons_getLessons[] = dataSource.filter(
          (data) => data.id === value
        );
        setdataSource(filteredData);
      }
    }
  };
  console.log(dataSource);
  return (
    <div className={styles.container}>
      <h1>Lecciones</h1>
      <div className={styles.lessonsContent}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Link to="/lessons/new">
          <Button type="primary" danger>
            Nueva Lección
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        sortDirections={["ascend"]}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Programa"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el programa?</p>
      </Modal>
    </div>
  );
};

export default Lessons;
