import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { vocabularyRegisterVocabularyWordVariables } from "../../__generated__/gql-types/vocabularyRegisterVocabularyWord";

const UseUpdateWords = () => {
  const client = useApolloClient();
  return useCallback(
    async (
      programInput: vocabularyRegisterVocabularyWordVariables,
      vocabularyUpdateVocabularyWordId: number
    ) => {
      return await client.mutate({
        mutation: UPDATE_VOCABULARY,
        fetchPolicy: "no-cache",
        variables: { ...programInput, vocabularyUpdateVocabularyWordId },
      });
    },
    [client]
  );
};

export default UseUpdateWords;

const UPDATE_VOCABULARY = gql`
  mutation vocabularyUpdateVocabularyWord(
    $vocabularyUpdateVocabularyWordId: Int!
    $vocabularyWordInput: VocabularyWordInput!
  ) {
    vocabularyUpdateVocabularyWord(
      id: $vocabularyUpdateVocabularyWordId
      vocabularyWordInput: $vocabularyWordInput
    ) {
      id
      gifFileId
      spanishWord
      englishWord
      synonyms
      wordType
      clue {
        id
        example
        phrase
        type
      }
      createdAt
      updatedAt
    }
  }
`;
