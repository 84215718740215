import { useDispatch, useSelector } from "react-redux";
import { TAGS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetTags_getTags } from "../__generated__/gql-types/GetTags";
import UseGetTags from "../api/useGetTags";

export const useTags = () => {
  const dispatch = useDispatch();
  const tagsSelector = useSelector(TAGS);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState<GetTags_getTags[]>([]);
  const getTags = UseGetTags();

  const fetchInfo = async () => {
    try {
      if (tagsSelector?.length > 0) {
        setTags(tagsSelector);
      } else {
        const tagsResponse = await getTags();
        const tagsData = tagsResponse?.sort(
          (a: GetTags_getTags, b: GetTags_getTags) =>
            Number(b?.id) - Number(a?.id)
        );
        tagsData && setTags(tagsData);
        dispatch({ type: "data/setTags", payload: tagsData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    tags,
    loading,
  };
};
