import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getToolsIAById,
  getToolsIAByIdVariables,
} from "../__generated__/gql-types/getToolsIAById";

const UseGetToolsIAById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getToolsIAByIdVariables) => {
      const result: ApolloQueryResult<getToolsIAById> = await client.query({
        query: GET_TOOLS_IA_BY_ID,
        fetchPolicy: "no-cache",
        variables,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getToolsIAById;
    },
    [client]
  );
};

export default UseGetToolsIAById;

const GET_TOOLS_IA_BY_ID = gql`
  query getToolsIAById($getToolsIaByIdId: ID) {
    getToolsIAById(id: $getToolsIaByIdId) {
      id
      title
      cover
      description
      prompt
      forms {
        label
        example_text
        number_of_lines
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
