import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  vocabularyCreateVocabularyLesson,
  vocabularyCreateVocabularyLessonVariables,
} from "../../__generated__/gql-types/vocabularyCreateVocabularyLesson";

const UseCreateVocabularyLesson = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: vocabularyCreateVocabularyLessonVariables) => {
      const result: ApolloQueryResult<vocabularyCreateVocabularyLesson> =
        await client.query({
          query: CREATE_VOCABULARY_LESSON,
          fetchPolicy: "no-cache",
          variables,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.vocabularyCreateVocabularyLesson;
    },
    [client]
  );
};

export default UseCreateVocabularyLesson;

const CREATE_VOCABULARY_LESSON = gql`
  mutation vocabularyCreateVocabularyLesson($vi: VocabularyInput!) {
    vocabularyCreateVocabularyLesson(vi: $vi) {
      id
      isBasics
      cover
      items {
        id
        wordId
        vw {
          englishWord
          clue {
            phrase
            example
          }
          spanishWord
        }
      }
    }
  }
`;
